import loadable from '@loadable/component';

import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';

import { getArtworkById } from 'actions/ArtworkActions';
import { getPackFiles, getPackById } from 'actions/PackActions';

import Loading from 'components/Loading';

import './Folder.scss';

const FlowPage = loadable(() => import('./Flow/Page'), { fallback: <Loading /> });
const PackPage = loadable(() => import('./Pack/Page'), { fallback: <Loading /> });

type Props = {
  type: string,
  match: Object,
  className: string,
  userProfile: Object,
};

const FolderPage = ({ className, type, match, userProfile, match: { params } }: Props) => {
  const artwork = useSelector(state => state.artwork.current);
  const artworkFiles = useSelector(state => state.artwork.files);
  const artworkTotalFiles = useSelector(state => state.artwork.totalFiles);
  const pack = useSelector(state => state.pack.current);
  const packFiles = useSelector(state => state.pack)?.[params.id] || [];
  const packTotalFiles = useSelector(state => state.pack.totalFiles);
  const visitorArtworkAccesses = useSelector(state => state.artwork.visitorArtworkAccesses);
  const visitorPackAccesses = useSelector(state => state.artwork.visitorPackAccesses);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (type === 'pack') {
      dispatch(getPackById(params.id));
      dispatch(getPackFiles(params.id));
    }
  }, [params.id, type, dispatch]);

  if ((type === 'flow' && !artwork.id) || (type === 'pack' && !pack)) {
    return <Loading fullPage />;
  }

  return (
    <div className={`Folder-page ${className || ''}`}>
      {type === 'flow' ? (
        <FlowPage
          data={{ ...artwork, artworkFiles, totalFiles: artworkTotalFiles }}
          onUpload={() => dispatch(getArtworkById(match.params.artworkName))}
          userProfile={userProfile}
          artwork={artwork}
          visitorArtworkAccesses={visitorArtworkAccesses}
          visitorPackAccesses={visitorPackAccesses}
        />
      ) : (
        <PackPage
          data={{ ...pack, packFiles, totalFiles: packTotalFiles, id: params.id, isPack: true }}
          onUpload={() => dispatch(getPackFiles(match.params.id))}
          artwork={artwork}
          userProfile={userProfile}
          visitorArtworkAccesses={visitorArtworkAccesses}
          visitorPackAccesses={visitorPackAccesses}
        />
      )}
    </div>
  );
};

export default withRouter(FolderPage);
